import React from 'react';
import './Home.css';
import Product from './Product';

function Home() {
  
  const products = [
    {
      id: "12321341",
      brand: "Eric Ries (Author)",
      title: "The Lean Startup: How Constant Innovation Creates Radically Successful Businesses Paperback",
      price: 11.96,
      rating: 5,
      image: "https://images-na.ssl-images-amazon.com/images/I/51Zymoq7UnL._SX325_BO1,204,203,200_.jpg"
    },
    {
      id: "49538094",
      brand: "Kenwood",
      title: "Kenwood kMix Stand Mixer for Baking, Stylish Kitchen Mixer with K-beater, Dough Hook and Whisk, 5 Litre Glass Bowl",
      price: 239.0,
      rating: 4,
      image: "https://images-na.ssl-images-amazon.com/images/I/81O%2BGNdkzKL._AC_SX450_.jpg"
    },
    {
      id: "4903850",
      brand: "Samsung",
      title: "Samsung LC49RG90SSUXEN 49' Curved LED Gaming Monitor",
      price: 199.99,
      rating: 3,
      image: "https://images-na.ssl-images-amazon.com/images/I/71Swqqe7XAL._AC_SX466_.jpg"
    },
    {
      id: "23445930",
      brand: "Amazon",
      title: "Amazon Echo (3rd generation) | Smart speaker with Alexa, Charcoal Fabric",
      price: 98.99,
      rating: 5,
      image: "https://media.very.co.uk/i/very/P6LTG_SQ1_0000000071_CHARCOAL_SLf?$300x400_retinamobilex2$"
    },
    {
      id: "3254354345",
      brand: "Apple",
      title: "New Apple iPad Pro (12.9-inch, Wi-Fi, 128GB) - Silver (4th Generation)",
      price: 598.99,
      rating: 4,
      image: "https://images-na.ssl-images-amazon.com/images/I/816ctt5WV5L._AC_SX385_.jpg"
    },
    {
      id: "90829332",
      brand: "Samsung",
      title: "Samsung LC49RG90SSUXEN 49' Curved LED Gaming Monitor - Super Ultra Wide Dual WQHD 5120 x 1440",
      price: 1094.98,
      rating: 4,
      image: "https://images-na.ssl-images-amazon.com/images/I/6125mFrzr6L._AC_SX355_.jpg"
    }
  ];

  return (
    <div className="home">
      <div className="home__container">
        <img
          className="home__image"
          src="https://m.media-amazon.com/images/I/71JBER9pf2L._SX3000_.jpg"
          alt="Amazon Home Banner"
        />
        
        <div className="home__row_wrapper">
        {/* 映射产品数组以渲染产品组件 */}
        {products.map(product => (
          <div key={product.id} className="home__row">
            <Product
              id={product.id}
              brand={product.brand}
              title={product.title}
              price={product.price}
              rating={product.rating}
              image={product.image}
            />
          </div>
        ))}
        </div>
      </div>
    </div>
  );
}

export default Home;
